<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          v-bind:darkMode="true"
          isBtn="bg-gradient-success"
        />
      </div>
    </div>
  </div>
  <br><br><br><br>
  <main class="mt-0 main-content">
    <section>

    <div class="py-4 container-fluid">

    </div>
    </section>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header pb-0">
              <div class="d-flex align-items-center">
                <p id="message" name="message" class="text-dark font-weight-bolder">{{message}}</p>
              </div>
            </div>
            
            <div class="card-body">

              <div class="card-header pb-0">
                <p class="text-dark font-weight-bolder">Xano Data</p>                                 
                <argon-button color="success" size="sm" class="ms-auto" @click="loadXano()">Load Xano Data</argon-button>
                &nbsp;
                <argon-button color="success" size="sm" class="ms-auto" @click="loadXanoCR()">Load Carrier Reports</argon-button>
                &nbsp;
                <argon-button color="success" size="sm" class="ms-auto" @click="loadXanoCRG()">Load Carrier Report Groups</argon-button>
                <hr>
              </div>
              
              <div class="card-header pb-0">
                <p class="text-dark font-weight-bolder">Datafeed Updates</p>                                 
                <argon-button color="success" size="sm" class="ms-auto" @click="loadSFDailyEnrollments()">Load Sunfire Daily Enrollments</argon-button>
                &nbsp;
                <argon-button color="success" size="sm" class="ms-auto" @click="loadEnrollhereAgents()">Load Enrollhere Agents</argon-button>
                &nbsp;
                <argon-button color="success" size="sm" class="ms-auto" @click="updateAgentState()">Update Agent State</argon-button>
                <hr>
              </div>
             
              <div class="card-header pb-0">                
                <p class="text-dark font-weight-bolder">Report loading</p>                                                 
                <argon-button color="success" size="sm" class="ms-auto" @click="loadRecons()">Load Recons</argon-button>
                &nbsp;
                <argon-button color="success" size="sm" class="ms-auto" @click="loadPolicyReports()">Load Policy Reports</argon-button>
                &nbsp;
                <argon-button color="success" size="sm" class="ms-auto" @click="loadUNL()">Load UNL</argon-button>
                &nbsp;
                <argon-button color="success" size="sm" class="ms-auto" @click="createRAWData()">Create RAW Data</argon-button>
                <hr>
              </div>
              
              <div class="card-header pb-0">                
                <p class="text-dark font-weight-bolder">R&D section</p>
                <argon-button color="success" size="sm" class="ms-auto" @click="docusignSend('jason@teamfym.com', 'Jason Duarte')">Docusign Send Agreement</argon-button>
                &nbsp;
                <argon-button color="success" size="sm" class="ms-auto" @click="docusignSend2('Jason Duarte', 'jason@teamfym.com', 'company')">Docusign Send Agreement2</argon-button>
                <hr>
              </div>

              {{python_output}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import router from "@/router";
import setTooltip from "@/assets/js/tooltip.js";
import Navbar from "@/examples/PageLayout/NavbarLoggedin.vue";
import ArgonButton from "@/components/ArgonButton.vue";

var message = 'DevSpace'

export default {
  name: "DevSpace",
  props: ['id'],
  data() {
    return {
      showMenu: false,
      elements: [],
      report_types: [],
      selectedReportId: 6,
      path: '',
      column_defs: '',
      column_count: 0,
      target_table: '',
      files: '',
      file_count: 0,
      message: '',
      python_output: '',
      role: sessionStorage.getItem('role')
    }
  },
  computed: {
    formattedDate(dt) {
      return new Date(dt).toLocaleDateString();
    },
    isFYMAdmin() {
      return this.role === 'FYM Admin';
    },
    isAgencyAdmin() {
      return this.role === 'Agency Admin';
    },
    isAgentManager() {
      return this.role === 'Agent Manager';
    },
    isAgent() {
      return this.role === 'Agent';
    }
  },
  methods: {
    async getLookups() {
      let response = await fetch("/apis/get_reports");
      this.report_types = await response.json();
    },
    async loadXano() {
      let response = await fetch("/apis/dataflow/loadXano");
      this.python_output = await response.text();
    },
    async loadXanoCR() {
      let response = await fetch("/apis/dataflow/loadXanoCR");
      this.python_output = await response.text();
    },
    async loadXanoCRG() {
      let response = await fetch("/apis/dataflow/loadXanoCRG");
      this.python_output = await response.text();
    },
    async createRAWData() {
      let response = await fetch("/apis/dataflow/createRAWData");
      this.python_output = await response.text();
    },
    async loadSFDailyEnrollments() {
      let response = await fetch("/apis/dataflow/loadSFDailyEnrollments");
      this.python_output = await response.text();
    },
    async loadEnrollhereAgents() {
      let response = await fetch("/apis/dataflow/loadEnrollhereAgents");
      this.python_output = await response.text();
    },
    async updateAgentState() {
      let response = await fetch("/apis/dataflow/updateAgentState");
      this.python_output = await response.text();
    },
    async loadRecons() {
      let response = await fetch("/apis/dataflow/loadRecons");
      this.python_output = await response.text();
    },
    async loadPolicyReports() {
      let response = await fetch("/apis/dataflow/loadPolicyReports");
      this.python_output = await response.text();
    },
    async loadUNL() {
      let response = await fetch("/apis/dataflow/loadUNL");
      this.python_output = await response.text();
    },
    async docusignSend(email, agent_name) {
      let response = await fetch("/apis/docusign/" + email + '/' + agent_name);
      this.message = await response.text();
    },
    async docusignSend2(name, email, company) {
      let response = await fetch("/apis/docusign2/" + name + '/' + email + '/' + company);
      this.message = await response.text();
    },
  },
  components: { Navbar, ArgonButton },
  created() {
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setTooltip();
  },
  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
  }
  
};
</script>