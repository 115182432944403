//Client side Jquery timer that logs out the user after 30 minutes of inactivity.        
var idleTimer = null;

var timer = function() {
        clearTimeout(idleTimer);
        
        idleTimer = setTimeout(function () {        
            window.location = '/apis/signout';        
        }, 60000 * 30);        

};

window.onload = function() {
    document.addEventListener("touchmove",timer);
    document.addEventListener("click",timer);
    document.addEventListener("mousemove",timer);
    document.addEventListener("keypress",timer);
    document.addEventListener("scroll",timer);
};
