<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          v-bind:darkMode="true"
          isBtn="bg-gradient-success"
        />
      </div>
    </div>
  </div>
  <br><br><br><br><br><br>
  <main class="mt-0 main-content">
    <section>

    <div class="py-4 container-fluid">

      <div class="card shadow-lg mt-n6">
        <div class="card-body p-3">
          <div class="row gx-4">  
            <div class="col-auto">
              <div class="avatar avatar-xl position-relative">
                <i class="ni ni-collection text-dark text-sm opacity-10"></i>
              </div>
            </div>
            <div class="col-auto my-auto">
              <div class="h-100">
                <h5 class="mb-1">File Uploads</h5>
              </div>
            </div>
            <div
              class="mx-auto mt-3 col-lg-4 col-md-6 my-sm-auto ms-sm-auto me-sm-0"
            >
              <div class="nav-wrapper position-relative end-0">
                <ul
                  class="p-1 bg-transparent nav nav-pills nav-fill"
                  role="tablist"
                >
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header pb-0">
              <div class="d-flex align-items-center">
                <p id="message" name="message" class="text-dark font-weight-bolder">{{message}}</p>

                Report Type
                <select id="report_type" name="report_type" class="form-select" v-model="selectedReportId" @change="getDefs(selectedReportId)" required>
                  <option v-for="element in report_types" :value="element.id" :key="element.id">
                    {{ element.name + ' - ' + element.frequency }}
                  </option>
                </select>

              </div>
            </div>
            
            <div class="card-body">
              {{ path }}
              <br>
              <h5>{{ column_count }} columns, target table: {{ target_table }}</h5><br>
              {{ column_defs }}
              <br><br>
              <h5 class="mb-1">File count {{ file_count }}</h5>  
              <br>

              <ul class="ul">  
                <li v-for="element in files" :value="element.name" :key="element.name">
                  <span class="td4">{{ element.name }} &nbsp;<i class='fa fa-check fa-lg' style='color:#99ff33' title='Loaded'></i></span>
                </li>
              </ul>

              <br>
              
              <argon-button v-if="isFYMAdmin" color="success" size="sm" class="ms-auto" @click="processReport(selectedReportId)">Process Reports</argon-button>
              <br><br>
              {{python_output}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import router from "@/router";
import setTooltip from "@/assets/js/tooltip.js";
import Navbar from "@/examples/PageLayout/NavbarLoggedin.vue";
import ArgonButton from "@/components/ArgonButton.vue";

var message = 'Reports'

export default {
  name: "Reports",
  props: ['id'],
  data() {
    return {
      showMenu: false,
      elements: [],
      report_types: [],
      selectedReportId: 6,
      path: '',
      column_defs: '',
      column_count: 0,
      target_table: '',
      files: '',
      file_count: 0,
      message: '',
      python_output: '',
      role: sessionStorage.getItem('role')
    }
  },
  computed: {
    formattedDate(dt) {
      return new Date(dt).toLocaleDateString();
    },
    isFYMAdmin() {
      return this.role === 'FYM Admin';
    },
    isAgencyAdmin() {
      return this.role === 'Agency Admin';
    },
    isAgentManager() {
      return this.role === 'Agent Manager';
    },
    isAgent() {
      return this.role === 'Agent';
    }
  },
  methods: {
    async getLookups() {
      let response2 = await fetch("/apis/get_reports");
      this.report_types = await response2.json();
    },
    async getDefs(report_id) {

      let response = await fetch("/apis/get_report_defs/" + report_id);
      this.elements = await response.json();   
      this.path = this.elements[0].path;
      this.column_defs = this.elements[0].column_defs;
      this.column_count = this.column_defs.split(',').length;
      this.target_table = this.elements[0].target_table
      this.files = this.elements[0].folderContents;
      this.file_count = this.elements[0].folderContents.length;
      //this.get_report_files();
    },
    async get_report_files() {
      let response = await fetch("/apis/get_report_files");
      this.elements = await response.json();
      this.files = this.elements.folderContents;
    },
    async processReport(report_type) {
      let response3 = await fetch("/apis/processReport/" + report_type);
      this.python_output = await response3.text();
    },
  },
  components: { Navbar, ArgonButton },
  created() {
    this.getLookups();
    //this.getDefs();
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setTooltip();
  },
  beforeMount() {
    this.$store.state.imageLayout = "profile-overview";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = true;
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
    this.$store.state.imageLayout = "default";
    this.$store.state.showNavbar = false;
    this.$store.state.showFooter = true;
    this.$store.state.hideConfigButton = false;
  }
  
};
</script>