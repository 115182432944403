<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          isBlur="blur  border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          v-bind:darkMode="true"
          isBtn="bg-gradient-success"
        />
      </div>
    </div>
  </div>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row">
            <div class="mx-auto col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
              <div class="card card-plain">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder">Sign In</h4>
                  <p class="mb-0">Enter your email and password to sign in</p>
                </div>
                <div class="card-body">
                  <form id="login-form" role="form" method="POST">
                    
                    <div v-if="this.$node_env === 'localhost' || this.$node_env === 'development'" class="mb-3">
                      <input id="email" type="email" class="form-control form-control-default invalid" placeholder="Email" name="email" v-model="signin.email" size="lg" required="true" autocomplete="email"/>
                    </div>
                    <div v-if="this.$node_env === 'localhost' || this.$node_env === 'development'" class="mb-3">
                      <input id="syspassword" name="syspassword" class="form-control form-control-default invalid" type="password" placeholder="Password" v-model="signin.syspassword" size="lg" autocomplete="current-password"/>
                    </div>
                    
                    <br>
                    <div id="si" class="text-center">
                      <argon-button
                        v-if="this.$node_env === 'localhost' || this.$node_env === 'development'"
                        id="signin_button"
                        class="ms-auto"
                        color="success"
                        fullWidth
                        size="lg"
                        @click.prevent="submitData()"
                      >Sign in</argon-button>
                      <br><br>
                      <GoogleLogin :callback="GoogleCallback"/>
                    </div>
                  </form>
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm">                    
                  </p>
                  <p id="message" name="message" class="text-dark font-weight-bolder">{{message}}</p>
                </div>
              </div>
            </div>
            <div
              class="top-0 my-auto text-center col-6 d-lg-flex d-none h-100 pe-0 position-absolute end-0 justify-content-center flex-column"
            >
              <div
                class="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden"
                style="background-image: url('/img/bg.jpg');
          background-size: cover;"
              >
                <span class="mask bg-gradient-dark opacity-6"></span>
                <h4
                  class="mt-5 text-white font-weight-bolder position-relative"
                >"Sign in here..."</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import router from "@/router";
import Navbar from "@/examples/PageLayout/Navbar.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonButton from "@/components/ArgonButton.vue";
//import GoogleSignIn from '@/components/GoogleSignIn.vue';

var message = "";

export default {
  name: "signin",
  components: {
    Navbar,
    ArgonSwitch,
    ArgonButton,
    //GoogleSignIn,
  }, 
  data() {
    return {
      signin: [],
      message
    }
  },
  methods: {
    GoogleCallback(response) {
      //console.log("Handle the response", response);

      const idToken = response.credential;
      const base64Url = idToken.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      const userInfo = JSON.parse(jsonPayload);
      
      //console.log(userInfo); // This is the Google User ID
      //userInfo.sub
      //userInfo.email
      //userInfo.given_name
      //userInfo.family_name
      
      fetch('/apis/auth/google', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({"id":userInfo.sub, "name":userInfo.name ,"email":userInfo.email}),
      })
      .then((res) => res.json())
      .then((data) => {
        if (data.result === 'loggedin') {
          sessionStorage.setItem("username", data.username);
          sessionStorage.setItem("role", data.role);
          sessionStorage.setItem("profile", data.profile);
          sessionStorage.setItem("company", data.company);
          sessionStorage.setItem("agency_id", data.agency_id);
          sessionStorage.setItem("agent_npn", data.agent_npn);
          sessionStorage.setItem("sysgroup", data.sysgroup);

          router.push('/dashboard');
        }
        else {
          this.message = data.message;
          //router.push('/');
        }
      })
      .catch((err) => console.log(err));
    },
    serializeForm(){
      
      let myForm = document.getElementById('login-form'); 
      let formData = new FormData(myForm);
      const data = {}; 
      // need to convert it before using not with XMLHttpRequest
      for (let [key, val] of formData.entries()) {
            Object.assign(data, {[key]: val})
      }
      return(data);
    },
    submitData: function() {
      fetch('/apis/signin', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(this.serializeForm()),
     })
      .then((res) => res.json())
      .then((data) => {
        if (data.result === 'loggedin') {
          sessionStorage.setItem("username", data.username);
          sessionStorage.setItem("role", data.role);
          sessionStorage.setItem("profile", data.profile);
          sessionStorage.setItem("company", data.company);
          sessionStorage.setItem("agency_id", data.agency_id);
          sessionStorage.setItem("agent_npn", data.agent_npn);
          sessionStorage.setItem("sysgroup", data.sysgroup);

          router.push('/dashboard');
        }
        else {
          this.message = data.message;
          //router.push('/');
        }
      })
      .catch((err) => console.log(err));
    },
    submitData2: function() {
      
      if(document.getElementById('email').value !== '') {
        fetch('/apis/forgotten_pw', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(this.serializeForm()),
        })
        .then((res) => res.json())
        .then((data) => {

          if (data.result === 'verficationsent') {
            this.message = data.message;
            //router.push('/')
          }
        })
        .catch((err) => console.log(err));
      }
      else {
        this.message = 'Enter your email address';
      }
    }
  },
  created() {
    this.$store.state.hideConfigButton = true;
    this.$store.state.showNavbar = false;
    this.$store.state.showSidenav = false;
    this.$store.state.showFooter = false;
    //body.classList.remove("bg-gray-100");
  },
  beforeUnmount() {
    this.$store.state.hideConfigButton = false;
    this.$store.state.showNavbar = true;
    this.$store.state.showSidenav = true;
    this.$store.state.showFooter = true;
    //body.classList.add("bg-gray-100");
  },
};
</script>
